import {Subscription} from './subscription.class';
import {CustomerTag} from './customer-tag.class';
import {CustomerCustomField} from './customer-custom-field.class';
import {CustomerNote, mapCustomerNote} from './customer-note.class';
import {Consent, mapConsent} from './consent.class';
import {ExternalData, mapExternalData} from './external-data.class';
import {CustomFieldAnswer, mapCustomFieldAnswer} from './custom-field-answer';
import {ConsentGrant, mapConsentGrant} from './consent-grant.class';
import {CustomerVerification, mapCustomerVerification} from './customer-verification.class';
import {CustomerNameHelper} from '../../core/helpers/customer-name-helper';

export class Customer {
    isSelected: boolean;
    verifiedAt: string;
    verificationType: string;

    // For exports only
    acceptedConsentIds: string;
    acceptedConsentNames: string;

    constructor(public id: string = null,
                public importId: string = null,
                public externalReference1: string = null,
                public type: string = null,
                public companyName: string = null,
                public firstName: string = null,
                public insertion: string = null,
                public lastName: string = null,
                public gender: string = null,
                public street: string = null,
                public houseNumber: string = null,
                public postalCode: string = null,
                public city: string = null,
                public province: string = null,
                public country: string = null,
                public language: string = null,
                public birthday: string = null,
                public email: string = null,
                public phoneNumber: string = null,
                public ibanAccountHolder: string = null,
                public iban: string = null,
                public orders: string[] = [],
                public subscriptions: Subscription[] = [],
                public guestManagerOrganisationId: string = null,
                public customerTags: CustomerTag[] = [],
                public customerNotes: CustomerNote[] = [],
                public externalData: ExternalData[] = [],
                public customerCustomFields?: CustomerCustomField[],
                public customFieldAnswers?: CustomFieldAnswer[],
                public guestManager?: any,
                public acceptedConsents?: Consent[],
                public consentGrants?: ConsentGrant[],
                public isDeleted?: boolean,
                public personalLink?: string,
                public blacklist?: any,
                public fullIban?: string,
                public verification?: CustomerVerification,
                public customFields?: any,
                public createdAt?: string) {
    }

    getName(): string {
        return CustomerNameHelper.getName(this);
    }

    getExtraDetails(): string {
        let details = '';

        if (this.street) {
            details = this.houseNumber ? `${this.street} ${this.houseNumber}` : `${this.street}`;
            details = this.city ? `${details}, ${this.city}` : details;
            details = this.externalReference1 ? `${details},` : details;
        }

        if (this.externalReference1) {
            details = `${details} ${this.externalReference1}`;
        }

        return details;
    }
}

export function mapCustomer(item: any): Customer {
    const customer = new Customer(
        item.id,
        item.importId,
        item.externalReference1,
        item.type,
        item.companyName,
        item.firstName,
        item.insertion,
        item.lastName,
        item.gender,
        item.street,
        item.houseNumber,
        item.postalCode,
        item.city,
        item.province,
        item.country,
        item.language,
        item.birthday,
        item.email,
        item.phoneNumber,
        item.ibanAccountHolder,
        item.iban,
        item.orders,
        item.subscriptions,
        item.guestManagerOrganisationId,
        item.customerTags,
        item.customerNotes ? item.customerNotes.map(customerNote => mapCustomerNote(customerNote)) : [],
        item.externalData ? item.externalData.map(externalData => mapExternalData(externalData)) : [],
        item.customerCustomFields,
        item.customFieldAnswers ? item.customFieldAnswers.map(customFieldAnswer => mapCustomFieldAnswer(customFieldAnswer)) : [],
        item.guestManager,
        item.acceptedConsents ? item.acceptedConsents.map(consent => mapConsent(consent)) : [],
        item.consentGrants ? item.consentGrants.map(consentGrant => mapConsentGrant(consentGrant)) : [],
        item.isArchived,
        item.personalLink,
        item.blacklist,
        item.fullIban,
        item.verification ? mapCustomerVerification(item.verification) : null,
        item.customFields,
        item.createdAt
    );

    if (customer.customFieldAnswers) {
        customer.customFieldAnswers.forEach((field, i) => {
            let value;

            if (field.customField?.type === 'SELECT' || field.customField?.type === 'RADIO') {
                value = field.customFieldOptionIds.length ? field.customFieldOptionIds[0] : null;
            } else if (field.customField?.type === 'MULTISELECT') {
                value = field.customFieldOptionIds.length ? field.customFieldOptionIds : [];
            } else {
                value = field.value;
            }

            customer.customFieldAnswers[i].value = value;
        });
    }

    return customer;
}
